<template>
  <v-app>
    <top-bar :navpos="nav" />
    <v-main>
      <v-container fluid>
        <div class="title">
          <v-data-table
            :headers="headers"
            :items="systems"
            item-key="id"
            :items-per-page="10"
            v-model="selectedRows"
            :search="filter"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <help />
                <v-toolbar-title>Systemübersicht</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-card-title>
                  <v-text-field
                    v-model="filter"
                    append-icon="mdi-magnify"
                    label="suchen"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Hinzufügen
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <p
                        v-html="formDescription"
                        class="subtitle-2 text-left"
                      ></p>
                      <v-divider class="mx-1"></v-divider>
                      <v-container>
                        <v-form ref="form_systems" v-model="valid">
                          <v-row no-gutters>
                            <v-col sm="12" md="12">
                              <v-text-field
                                v-model="neuArc.bezeichnung"
                                label="System"
                                :rules="rules.bezeichnung_rule"
                              ></v-text-field>
                            </v-col>
                            <v-col sm="12" md="12">
                              <v-select
                                v-model="neuArc.architekturTyp"
                                :items="arcTypes"
                                item-text="name"
                                item-value="id"
                                label="Architektur"
                              ></v-select>
                            </v-col>

                            <v-col sm="12" md="12" cols="12">
                              <v-textarea
                                v-model="neuArc.beschreibung"
                                label="Beschreibung"
                                auto-grow
                                rows="5"
                                :rules="rules.beschreibung_rule"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col sm="12">
                              <div v-for="item in msg" :key="item">
                                {{ item }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close">
                        Abbruch
                      </v-btn>
                      <v-btn color="primary" text @click="addNewSystem">
                        neu anlegen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="registerSelectedSystem(item)"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span> {{ item.bezeichnung }} bearbeiten</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
        <v-spacer></v-spacer>
        <v-divider class="mx-6"></v-divider>
      </v-container>
      <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Schliessen
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import TopBar from "../../../components/TopBar.vue";
import SystemService from "@/services/old/SystemService.js";
import Help from "@/components/old/help/SystemUebersichtHelp.vue";

const tableHeaders = [
  { text: "System", value: "bezeichnung", filterable: true },
  { text: "Architektur", value: "architekturTyp", filterable: false },
  {
    text: "Beschreibung",
    value: "beschreibung",
    sortable: false,
    filterable: false
  },
  { text: "Aktionen", value: "actions", sortable: false, filterable: false }
];

const architekturen = [
  { id: 1, name: "2-Schichten" },
  { id: 2, name: "3-Schichten" },
  { id: 3, name: "Batch" }
];

const nav_items = [
  {
    text: "Main",
    disabled: false,
    to: { path: "/main" }
  },
  {
    text: "Main(old)",
    disabled: false,
    to: { path: "main" }
  },
  {
    text: "Architekturerfassung",
    disabled: true,
    to: { path: "architekturerfassung" }
  }
];

export default {
  components: { TopBar, Help },
  name: "Architekturerfassung",
  props: {},
  data() {
    return {
      selectedRows: [],
      filter: "",
      msg: "",
      dialog: false,
      valid: true,
      headers: tableHeaders,
      arcTypes: architekturen,
      nav: nav_items,
      snackbar_timeout: 2000,
      message: "",
      snackbar: false,
      neuArc: {
        architekturTyp: "",
        bezeichnung: "",
        beschreibung: ""
      },
      rules: {
        beschreibung_rule: [v => !!v || "Beschreibung erforderlich."],
        bezeichnung_rule: [v => !!v || "Beschreibung erforderlich."]
      }
    };
  },
  created() {
    // this.$store.dispatch("getSystems");
    this.initialize();
  },
  computed: {
    systems() {
      return this.$store.getters.systeme;
    },
    formTitle() {
      return "System hinzufügen";
    },
    formDescription() {
      return "Spezifizieren Sie das System.";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    initialize() {
      this.neuArc = {};
      this.$store.dispatch("getSystems");
    },
    registerSelectedSystem(row) {
      try {
        this.$store.dispatch("registerSystem", row);
        this.$router.push({ path: "/" + "old/" + "architekturUebersicht" });
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    },
    architekturTypeText(row) {
      return this.architekturen.find(function(item) {
        return item.id === parseInt(row.architekturTyp);
      }).name;
    },
    async addNewSystem() {
      this.validate();
      if (this.valid) {
        try {
          await SystemService.addNewSystem(this.neuArc);

          this.$store.dispatch("getSystems");

          this.message = "Änderungen wurden gespeichert.";
          this.form_saved = true;
          this.close();
        } catch (err) {
          this.message = err;
        }
        this.initialize();
        this.snackbar = true;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.isEditModus = false;
      });
    },
    save() {
      this.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          Object.assign(this.systems[this.editedIndex], this.editedItem);
        } else {
          this.systems.push(this.editedItem);
        }
        this.close();
        this.message = "Änderungen wurden noch nicht gespeichert.";
        this.snackbar = true;
        this.form_saved = false;
      }
    },
    validate() {
      this.$refs.form_systems.validate();
    }
  }
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.title {
  background-color: #ffffff;
  margin-top: 5px;
  align-self: center;
}
</style>
